@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --category-configurable-attributes-thumb-color: #{$color-neutral-90};
    --category-configurable-attributes-track-color: #{$color-neutral-30};
}

.CategoryConfigurableAttributes {
    &-FilterSearcher {
        margin-bottom: 22px;
        position: relative;

        input[type="text"] {
            height: 40px;
            padding-left: 50px;
            --input-border-color: #{$color-neutral-20};
            --input-background-color: #{$color-neutral-20};
        }
    }

    &-FilterSearcherIcon {
        position: absolute;
        left: 18px;
        top: 5px;
        z-index: 1;
    }

    &-Attributes {
        max-height: 300px;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 4px;
            cursor: pointer;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--category-configurable-attributes-thumb-color);
        }

        &::-webkit-scrollbar-track {
            background-color: var(--category-configurable-attributes-track-color);
        }

        .ProductAttributeValue {
            &:last-child {
                .Field {
                    margin-bottom: 0;
                }
            }
        }

        .Field {
            margin-bottom: 18px;

            input {
                &[type="checkbox"] {
                    display: none;
                }
            }

            .input-control {
                --box-color: #{$color-neutral-35};

                border: 1px solid $color-neutral-35;
            }
        }
    }

    &-Apply {
        button {
            width: 100%;
        }
    }

    &-ResetOption {
        text-align: center;
        margin-bottom: 24px;
        font-size: 12px;
        width: 100%;

        span {
            cursor: pointer;

            &::after {
                content: 'x';
                padding-left: 8px;
            }
        }
    }

    &-SelectedCounter {
        background: $color-primary-85;
        color: $color-neutral-5;
        padding: 4px 10px 2px;
        display: inline-block;
        margin-left: 10px;
        font-size: 14px;
    }
}

@include mobileAndTablet {
    .CategoryConfigurableAttributes {
        &-Attributes {
            max-height: initial;
            overflow-y: initial;

            .Field {
                .Field-CheckboxLabel {
                    max-width: 100%;
                }
            }
        }
    }
}

@include desktopAndAbove {
    .ProductConfigurableAttributes {
        &-ExpandableContentButton {
            &_notActive {
                opacity: 0.48;
            }
        }
    }
}
