@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CategoryPage {
    margin-top: 0;

    &-Wrapper {
        @include page-container;

        @include desktopAndAbove {
            display: grid;
            grid-column-gap: 48px;
        }

        grid-template-columns: 286px auto;

        &_hideProducts {
            display: block;

            .CategoryPage-CMS {
                @include mobile {
                    margin-block-start: 0;
                }
            }
        }
    }

    @include mobile {
        .NotificationList {
            width: auto;
        }
    }

    &-Content {
        @include page-container();
    }

    &-ProductListWrapper {
        @include mobile {
            padding-block-start: 16px;
        }

        &_isPrerendered {
            @include mobile {
                padding-block-start: 120px;
            }
        }
    }

    &-ItemsCount {
        padding: 0;

        @include mobile {
            order: 1;
            padding-block: 4px 12px;
            text-align: center;
            grid-column: span 2;
            padding-inline: 14px;
        }

        @include desktop {
            margin-block-end: 0;
        }
    }

    &-Filter {
        margin-left: 24px;
        color: $color-neutral-90;
        border: 1px solid $color-neutral-90;
        height: 40px;
        padding: 0 16px;
        width: 200px;
        font-size: 13px;
        text-align: left;
        position: relative;

        svg {
            position: absolute;
            right: 15px;
            top: 5px;
        }

        @include desktop {
            &Placeholder {
                position: relative;
                grid-row: 1/6;
                width: 100%;
                opacity: 1;
                pointer-events: all;
                display: flex;
                flex-direction: column;
                height: auto;
                overflow: visible;
            }
        }
    }

    &-LayoutButtons {
        display: flex;
        align-items: center;

        button {
            color: transparent;
            margin-inline-end: 10px;
            opacity: 0.4;

            @include default-transition(opacity, .2s);

            &:hover, &.grid_isActive, &.list_isActive {
                opacity: 1;
            }
        }

        svg {
            display: block;
        }
    }

    &-NameCount {
        position: relative;
        display: flex;
        margin-bottom: 10px;
        padding-top: 35px;

        span {
            font-size: 24px;
            font-weight: 400;
            line-height: 32px;
        }

        .TypographyHeader {
            margin-bottom: 0;
        }
    }

    &-Count {
        margin-left: 15px;
        top: 5px;
    }

    &-LayoutWrapper {
        display: flex;

        .Pagination {
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    &-Miscellaneous {
        padding-bottom: 50px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        margin-bottom: 50px;
    }

    &-ProductLisrWrapper {
        margin-bottom: 60px;
    }

    &-DescriptionWithImage {
        @include page-container();

        display: flex;
        flex-direction: row;
        margin-bottom: 64px;
    }

    &-Description {
        box-sizing: content-box;
        padding: 48px;
        background-color: $color-neutral-25;
        flex: 1;
    }

    &-DescriptionText {
        &, & p {
            font-size: 16px;
            color: $color-neutral-50;
            line-height: 28px;
            margin-bottom: 0;
        }
    }

    &-DescriptionHeader {
        font-size: 30px;
        font-weight: 400;
        letter-spacing: 0px;
        color: $color-neutral-90;
        margin-bottom: 16px;
        line-height: 42px;
        font-family: $font-dm-serif-display;
    }

    &-DescriptionImage {
        max-width: 730px;

        img {
            width: 100%;
            display: block;
        }
    }

    &-CategoryTopBanner, &-CategoryBottomBanner {
        @include page-container;
    }

    &-BrandBottom {
        margin-bottom: 120px;
    }

    &_isBrand {
        .CategoryPage {
            &-NameCount {
                align-items: center;

                span {
                    font-size: 37px;
                    line-height: 50px;
                    font-family: "DM Serif Display", sans-serif;
                }
            }
        }
    }
}

@include mobile {
    .CategoryPage {
        &-NameCount {
            .TypographyHeader {
                font-size: 24px;
            }
        }


        &-DescriptionWithImage {
            flex-direction: column;
            margin-bottom: 24px;
        }

        &-DescriptionImage {
            margin-left: -16px;
            margin-right: -16px;

            img {
                min-height: 150px;
            }
        }

        &-Description {
            padding: 0;
            order: 1;
            background: none;
            margin-top: -52px;
            z-index: 1;
            margin-left: -16px;
            margin-right: -16px;

            &_noImage {
                margin-top: 0;
            }
        }

        &-DescriptionHeader, &-DescriptionText {
            background-color: $color-neutral-25;
        }

        &-DescriptionHeader {
            display: inline-block;
            padding: 10px 20px 0;
            margin-bottom: 0;
        }

        &-DescriptionText {
            padding: 15px 20px 24px;

            &, & p {
                font-size: 14px;
                line-height: 24px;
            }
        }

        &-LayoutButtons {
            display: none;
        }

        &-Filter svg, .FieldSelect-Arrow {
            right: 5px;
        }

        &-Subheading {
            background: $color-primary-85;
            color: $color-neutral-5;
            padding: 2px 10px 2px;
            display: inline-block;
            margin-left: 10px;
            font-size: 14px;
        }
    }
}

@include mobileAndTablet {
    .CategoryPage {
        &-NameCount {
            padding-top: 24px;
        }

        &-Miscellaneous {
            margin-bottom: 20px;
            padding-bottom: 0;
            border: 0;
        }

        &-LayoutWrapper {
            .Pagination {
                display: none;
            }
        }

        &-BrandBottom {
            margin-bottom: 64px;
        }
    }

    .CategoryDetails {
        margin: 5rem;
    }
}

@include tablet {
    .CategoryPage {
        &-Description {
            padding: 30px;
        }

        &-LayoutButtons {
            position: absolute;
            right: 0;
            top: -38px;

            button {
                margin-inline-start: 10px;
                margin-inline-end: 0;
            }
        }
    }
}

@include tabletAndAbove {
    .CategoryPage {
        &-DescriptionImage {
            width: 34%;

            img {
                object-fit: cover;
                height: 100%;
            }
        }
    }
}

@include desktopAndAbove {
    .CategoryPage {
        &-Sidebar {
            padding-top: 30px;
        }

        &-Filter {
            display: none;
        }
    }
}

@include above1300 {
    .CategoryPage {
        &-DescriptionImage {
            width: 100%;

            img {
                object-fit: initial;
                height: 100%;
            }
        }
    }
}



