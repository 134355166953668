@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CategoryTree {
    @include page-container();

    margin-bottom: 48px;

    &-Categories {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        list-style-type: none;
        gap: 14px;
        padding-left: 0;
        margin-bottom: 0;
    }

    &-Category {
        display: flex;
        color: $dark-transparent;
        font-size: 14px;
        padding: 12px 24px;

        @include default-transition();

        &:hover {
            &::before {
                opacity: 0;
            }
        }
    }

    &-CategoryItem {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        background-color: $color-neutral-20;
        border: 1px solid transparent;
        margin-bottom: 0;

        @include default-transition();

        &:hover {
            border: 1px solid $default-primary-base-color;

            .CategoryTree-Category, .CategoryTree-Count {
                font-weight: 400;
                color: $default-primary-base-color;
            }
        }

        &::before {
            display: none;
        }
    }

    &-Count {
        color: $dark-transparent;
        padding-left: 5px;

        @include default-transition();
    }

    &_isSecondaryVariant {
        margin-top: 50px;
        margin-bottom: 80px;

        .CategoryTree {
            &-BackButton {
                button {
                    font-weight: 500;
                }
            }

            &-CategoryItem {
                padding-left: 0;
            }

            &-Categories {
                gap: 10px;
            }

            &-Category {
                width: 246px;
                padding: 12px 10px;
                text-align: center;
                display: block;
                font-weight: 500;
                font-size: 14px;

                &:hover {
                    font-weight: 500;
                }
            }
        }
    }
}

@include mobileAndTablet {
    .CategoryTree {
        &_isSecondaryVariant {
            margin-top: 35px;
            margin-bottom: 70px;

            .CategoryTree {
                &-Category {
                    width: 307px;
                    font-size: 16px;
                    padding: 14px 10px;
                }

                &-CategoryItem {
                    padding: 0;
                }
            }
        }
    }
}

@include mobile {
    .CategoryTree {
        margin-bottom: 40px;

        &-CategoryItem {
            padding: 6px 12px;
        }

        &-Category {
            font-size: 12px;
            padding: 0;
        }

        &_isSecondaryVariant {
            .CategoryTree {
                &-Category {
                    width: 100%;
                }

                &-CategoryItem {
                    width: calc(50% - 5px);
                }
            }
        }
    }
}

@include below590 {
    .CategoryTree {
        &_isSecondaryVariant {
            .CategoryTree {
                &-CategoryItem {
                    width: 100%;
                }
            }
        }
    }
}
