@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --category-price-slider-track-height: 3px;
    --category-price-slider-main-color: #{$color-neutral-90};
    --category-price-slider-secondary-color: #{$color-neutral-40};
    --category-price-slider-input-bg: #{$color-neutral-20};
    --category-price-slider-input-color: #{$color-neutral-70};
}

.CategoryPriceSlider {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .MuiSlider {
        &-root {
            width: calc(100% - 18px);
            margin-left: 8px;
            height: var(--category-price-slider-track-height);
        }

        &-track {
            height: var(--category-price-slider-track-height);
            background: var(--category-price-slider-main-color);
        }

        &-rail {
            height: var(--category-price-slider-track-height);
            background: var(--category-price-slider-secondary-color);
        }

        &-thumb {
            width: 14px;
            height: 14px;
            background: var(--category-price-slider-main-color);
            border: 0;
            border-radius: 50%;
            margin-top: -6px;

            &::after {
                display: none;
            }

            &.MuiSlider-active,
            &.Mui-focusVisible,
            &:hover {
                box-shadow: none;
            }
        }
    }

    &-InputsWrapper {
        margin: 20px 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &-Input {
        width: 100px;
        background: var(--category-price-slider-input-bg);
        color: var(--category-price-slider-input-color);
        border: 0;
        height: 32px;
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
        text-align: center;
        pointer-events: auto;

        &:not(:first-of-type) {
            margin-top: 0;
        }

        &:focus {
            border-width: 1px;
        }
    }

    button {
        width: 100%;
    }
}
