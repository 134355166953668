@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CategoryAdditionalDetails {
    h1, h2, h3, h4, h5, h6 {
        font-size: 20px;
        line-height: 27px;
        margin-bottom: 27px;
        font-weight: 400;
        color: $color-neutral-90;
    }

    &, p {
        font-size: 14px;
        line-height: 21px;
        color: $color-neutral-70;
    }

    strong {
        color: $color-neutral-70;
        font-weight: 400;
    }

    &-Description {
        img {
            height: auto;
            width: auto;
        }

        @include page-container();

        margin: 0 auto 100px;
    }
}

@include mobile {
    .CategoryAdditionalDetails {
        &-Description {
            margin: 0 auto 65px;
        }
    }
}

