@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CategorySort {
    margin-left: 40px;
    margin-top: 2px;

    .Field {
        display: flex;
        align-items: center;
        margin-bottom: 0;

        select {
            height: 40px;
            padding-left: 16px;
            padding-top: 10px;
            padding-bottom: 10px;
            font-size: 13px;
        }
    }

    label {
        padding-bottom: 0;
        margin-right: 16px;
        font-size: 13px;
    }
}

@include mobileAndTablet {
    .CategorySort {
        margin-left: 0;

        label {
            display: none;
        }
    }
}
