@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --category-filter-overlay-desktop-color: #979797;
    --category-filter-overlay-selected-color: var(--primary-base-color);
    --category-filter-overlay-hover-color: var(--primary-light-color);
}

.CategoryFilterOverlay {
    @include desktopAndAbove {
        position: relative;
        grid-row: 1/6;
        width: 100%;
        opacity: 1;
        pointer-events: all;
        display: flex;
        flex-direction: column;
        height: auto;
        overflow: visible;
    }

    @include mobileAndTablet {
        &-Wrapper {
            display: grid;
            grid-template-rows: min-content auto min-content;
            height: 100%;
            overflow-y: auto;
        }
    }

    &-NoResults {
        margin: 0;
        padding-block-start: 12px;
        padding-block-end: 0;
        padding-inline: 12px;

        @include mobile {
            padding-block-start: 14px;
            padding-block-end: 0;
            padding-inline: 14px;
        }

        @include desktop {
            padding-block-start: 0;
            padding-block-end: 12px;
            padding-inline: 0;
        }
    }

    &-Heading {
        text-transform: none;
        margin-block-start: 0;
        margin-block-end: 0;

        &_isContentFiltered {
            display: none;
        }
    }

    &-Item {
        @include desktop {
            margin-inline-end: 12px;
        }

        &_isSelected {
            &:not(.Swatch_type_color):not(.Swatch_type_cat) {
                --category-filter-overlay-desktop-color: var(--category-filter-overlay-selected-color);
            }
        }

        &:not(.Swatch_type_color):not(.Swatch_type_cat) {
            @include desktop {
                padding: 0 12px;
                border: 1px solid var(--category-filter-overlay-desktop-color);
                color: var(--category-filter-overlay-desktop-color);
                margin-inline-end: 6px;
            }

            &:hover {
                @include desktop {
                    --category-filter-overlay-desktop-color: var(--category-filter-overlay-hover-color);
                }
            }
        }

        &_type_cat {
            &::before {
                @include desktop {
                    content: '\2022';
                    margin-inline-end: 12px;
                    margin-inline-start: 2px;
                }
            }
        }
    }

    &-Placeholder {
        padding: 12px;

        @include mobile {
            padding: 14px;
        }
    }

    &-Attributes {
        --option-margin-block: 6px;
        --option-margin-inline: 6px;

        @include mobile {
            --option-margin-block: 7px;
            --option-margin-inline: 7px;
        }
    }

    &-Filter {
        order: 50;

        @include desktop {
            margin-block-end: 12px;
        }
    }

    &-SeeResults,
    &-ResetButton {
        @include mobileAndTablet {
            inset-block-end: 0;
            width: 100%;
            inset-inline-start: 0;
        }
    }

    &-SeeResults {
        padding-top: 20px;
        border-top: 1px solid $color-neutral-40;

        @include desktopAndAbove {
            display: none;
        }

        @include mobileAndTablet {
            position: sticky;
            background-color: var(--color-white);
        }

        .CategoryFilterOverlay-NoResults ~ & {
            @include mobile {
                display: none;
            }
        }
    }

    &-Button.Button {
        width: 100%;
    }

    &-ResetButton {
        @include desktop {
            margin-block-end: 12px;
        }

        .CategoryFilterOverlay-NoResults ~ & {
            @include mobile {
                display: block;
            }
        }
    }

    li {
        padding-inline-start: 0;

        &::before {
            content: none;
        }

        &:last-child {
            @include mobile {
                margin-block-end: 8px;
            }
        }
    }

    .TextPlaceholder,
    .ProductConfigurableAttributes-Placeholder {
        opacity: 0;
        animation: appear 100ms 300ms forwards;
    }

    .ExpandableContent {
        &-Heading {
            font-size: 14px;
            font-weight: 400;
        }

        .CategoryConfigurableAttributes {
            &-Attributes {
                margin-bottom: 30px;
            }
        }
    }

    &-ResetAll {
        text-align: center;
        font-size: 12px;

        span {
            cursor: pointer;

            &::after {
                content: 'x';
                padding-left: 8px;
            }
        }
    }
}

@include mobile {
    .CategoryFilterOverlay {
        &-ResetAll {
            display: flex;
            justify-content: center;
            margin-bottom: 15px;
        }
    }
}

@include mobileAndTablet {
    .CategoryFilterOverlay {
        height: 100%;

        .ExpandableContent {
            border: 0;
            margin-bottom: 15px;
            margin-right: 0;

            &:last-of-type {
                border: 0;
            }

            &-Button {
                padding: 0;
            }
        }
    }
}

@include desktopAndAbove {
    .CategoryFilterOverlay {
        margin-bottom: 50px;

        &-Attributes {
            display: flex;
        }

        &-FiltersWrap {
            display: flex;
            align-items: center;
        }

        &-FiltersLabel {
            font-size: 14px;
            padding-right: 24px;
        }
    }
}
